<template>
  <div>
    <!-- title -->
    <el-card class="title">
      <el-row>
        <el-col :span="2">
          <p>{{ $t("purchase.productName") }}</p>
        </el-col>
        <el-col :span="4" align="center">
          <p>{{ $t("purchase.quantity") }}</p>
        </el-col>
        <el-col :span="5" align="center">
          <p>{{ $t("purchase.purchaseType") }}</p>
        </el-col>
        <el-col :span="5" align="center">
          <p>{{ $t("purchase.category") }}</p>
        </el-col>
        <el-col :span="4" align="center">
          <p>{{ $t("purchase.destination") }}</p>
        </el-col>
        <el-col :span="4" align="center">
          <p>{{ $t("purchase.operation") }}</p>
        </el-col>
      </el-row>
    </el-card>
    <!-- main -->
    <el-card class="main" v-for="(item, index) in rows" :key="index">
      <el-row>
        <el-col :span="2">
          <p>{{ item.productName }}</p>
        </el-col>
        <el-col :span="4" align="center">
          <p>{{ item.quantity }}</p>
        </el-col>
        <el-col :span="5" align="center">
          <p>{{ item.purchaseType }}</p>
        </el-col>
        <el-col :span="5" align="center">
          <p>{{ item.categoryName }}111111111</p>
        </el-col>
        <el-col :span="4" align="center">
          <p>{{ item.destination }}</p>
        </el-col>
        <el-col :span="4" align="center">
          <p class="p1 details" @click="details(item._id)">{{ $t("common.view") }}</p>
        </el-col>
      </el-row>
    </el-card>

    <div class="pagination-rounded" v-if="totalRows > perPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="perPage"
        layout="total, prev, pager, next, jumper"
        :total="totalRows"
        jumper-label="dd"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDatas } from "../../helper/api";
export default {
  data() {
    return {
      id: "",
      rows: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
    };
  },
  created() {
    this.store();
  },
  mounted() {},
  methods: {
    store() {
      getDatas("GET", "purchase").then((res) => {
        console.log(res);
        this.rows = res.data.rows;
        this.totalRows=res.data.count
      });
    },
    details(id) {
      console.log(id);
      this.$router.push({
        path: "/purchase/purchaseDetail?id=" + id,
        query: {
          id,
        },
      });
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.currentPage = 1;
      this.orderLists(this.currentPage, this.activeName);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.orderLists(this.currentPage, this.activeName);
    },
  },
};
</script>

<style scoped>
.title {
  background: #ffdcbc;
  font-size: 13px;
  font-weight: 700;
}
.main {
  font-size: 13px;
  font-weight: 700;
  margin-top: 15px;
}
.main .p1 {
  color: #ff0a0a;
}
.details{
  cursor: pointer;
}
.pagination-rounded {
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
</style>